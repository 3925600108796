export default {
  props: ["nameScreen"],
  data() {
    return {
      menu: false,
      sSearch: "",
      sManagers:"",
      aManagers:[],
      date: null,
      menuDateStart: false,
      menuDateEnd: false,
      dateStart: "",
      dateEnd: "",
      texts:'',
      isSmall:false
    };
  },
  mounted() {
    this.$store.commit("setSearch", "");
    this.$store.commit("setStartDate", null);
    this.$store.commit("setEndDate", null);
  },
  beforeMount(){
    this.texts = FILE.claimsTexts[this.selectLanguage];

    this.getManagers();
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize() {
      this.isSmall = window.innerWidth > 960;
    },
    // obtiene los encargados disponibles para mostrarlos en el filtro
    getManagers(){
      DB.get(`${URI}/api/v1/${this.selectLanguage}/administrators`, {
        headers: {
          Authorization: "Bearer " + this.$store.state.sToken,
        },
        params: { 
          bShowSuper:true
         },
      })
        .then((response) => {
          this.aManagers = response.data.results.map((e) => {
            return{
              ...e,
              sFullName: e.sName + ' ' + e.sLastname
            }
          });
        })
        .catch((error) => {
                    this.mixError(error.response.data.message,error.response.status);
        });
    },
  },
  computed: {
    selectLanguage() {
      return this.$store.state.language;
    },
  },
  watch: {
    selectLanguage: function () {
      if (this.selectLanguage) {
        this.texts = FILE.claimsTexts[this.selectLanguage];
      }
    },
    dateStart: function () {
      if (this.dateStart === null) {
        this.dateStart = "", this.dateEnd = ""
      }
      this.$store.commit("setStartDate", this.dateStart);
    },
    dateEnd: function () {
      if (this.dateEnd === null) {
        this.dateStart = "", this.dateEnd = ""
      }
      this.$store.commit("setEndDate", this.dateEnd);
    },
    // set state to local search
    sSearch: function () {
      this.$store.commit("setSearch", this.sSearch);
    },
    sManagers: function(){
      this.$store.commit("setManagers",this.sManagers);
    }
  },
};